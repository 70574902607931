
import { computed, defineComponent, inject, onUnmounted, reactive } from 'vue';
import { useQuery } from 'vue-query';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import {
  ArticleDocked,
  ArticleDockedType,
  ArticleSetDocked,
  ArticleSetDockedType,
} from '@/components/layouts/Base.vue';
import { ARTICLE_RELATIONSHIP, RESOURCE_KINDS } from '@/config/enums';
import api from '@/services/api';
import mapResourceId from '@/services/mapping/mapResourceId';
import { State } from '@/store';

import Actions from './Actions.vue';
import Column from './Column.vue';
import Content from './Content.vue';
import Entities from './Entities.vue';
import Links from './Links.vue';
import Related from './Related.vue';
import Versions from './Versions.vue';

export default defineComponent({
  components: {
    Actions,
    Column,
    Entities,
    Versions,
    Links,
    Content,
    Related,
  },

  props: {
    overviewRouteName: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const store = useStore<State>();
    const router = useRouter();
    const route = useRoute();

    const docked = inject(ArticleDocked) as ArticleDockedType;
    const setDocked = inject(ArticleSetDocked) as ArticleSetDockedType;

    const articleId = computed(() => route.params.articleId as string);

    const resource = computed(() => {
      return route.params.resourceId && route.params.resourceId !== 'null'
        ? mapResourceId(
            store.state.workspaces,
            route.params.resourceId as string,
          )?.resource
        : undefined;
    });

    const apiModule = computed(() => {
      if (!resource.value) return api.search;

      switch (resource.value?.resourceKind) {
        case RESOURCE_KINDS.CALENDARS:
          return api.calendars;
        case RESOURCE_KINDS.QUERY:
          return api.queries;
        case RESOURCE_KINDS.SOURCE:
        default:
          return api.sources;
      }
    });

    const setIsRead = () => {
      const isRead = store.state.collections.read.includes(articleId.value);
      if (!isRead) store.commit('collections/addRead', [articleId.value]);
    };

    const fetchArticle = () => {
      if (articleId.value) {
        setIsRead();

        return apiModule.value.article(
          resource.value?.resourceId as string,
          articleId.value,
          {
            highlight: resource.value?.resourceKind === RESOURCE_KINDS.QUERY,
            versions: true,
            includeRelated: 5,
          },
        );
      }
    };

    const queryKey = reactive(['article', { id: articleId }]);
    const query = useQuery(queryKey, fetchArticle);

    const handleClose = () => {
      router.push({
        name: props.overviewRouteName,
        params: {
          id: route.params.id,
          resourceId: route.params.resourceId,
        },
      });
    };

    const toggleDocked = () => {
      setDocked(!docked.value);
    };

    onUnmounted(() => {
      setDocked(false);
    });

    return {
      ARTICLE_RELATIONSHIP,
      articleId,
      resource,
      docked,
      handleClose,
      toggleDocked,
      ...query,
    };
  },
});
