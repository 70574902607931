import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-681ac3b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row flex--nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_anp_popover = _resolveComponent("anp-popover")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_LiveAgenda = _resolveComponent("LiveAgenda")!
  const _component_va_progress_circle = _resolveComponent("va-progress-circle")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_anp_column_item = _resolveComponent("anp-column-item")!
  const _component_anp_column_skeleton = _resolveComponent("anp-column-skeleton")!
  const _component_anp_column_empty = _resolveComponent("anp-column-empty")!
  const _component_anp_column_all_loaded = _resolveComponent("anp-column-all-loaded")!
  const _component_anp_column_loader = _resolveComponent("anp-column-loader")!
  const _component_anp_column = _resolveComponent("anp-column")!

  return (_openBlock(), _createBlock(_component_anp_column, {
    class: _normalizeClass({ big: _ctx.resizeIsBig }),
    id: _ctx.anchor,
    title: _ctx.resource.title,
    anchor: _ctx.anchor,
    ref: 
      (element) => {
        _ctx.root = element?.$el;
      }
    
  }, {
    actions: _withCtx(() => [
      (_ctx.draggable)
        ? (_openBlock(), _createBlock(_component_anp_popover, {
            key: 0,
            message: _ctx.$t('actions.drag')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_va_icon, {
                class: "handle",
                name: "drag_handle",
                color: "gray"
              })
            ]),
            _: 1
          }, 8, ["message"]))
        : _createCommentVNode("", true),
      _createVNode(_component_anp_popover, {
        message: 
          _ctx.resizeIsBig ? _ctx.$t('common.make-smaller') : _ctx.$t('common.make-wider')
        
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_button, {
            class: "resize",
            icon: _ctx.resizeIsBig ? 'unfold_less' : 'unfold_more',
            color: "gray",
            flat: "",
            onClick: _ctx.handleResize
          }, null, 8, ["icon", "onClick"])
        ]),
        _: 1
      }, 8, ["message"]),
      _createVNode(_component_anp_popover, {
        message: _ctx.$t('common.search')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_button, {
            icon: "search",
            color: "primary",
            flat: "",
            onClick: _ctx.toggleSearch
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["message"]),
      _createVNode(_component_anp_popover, {
        message: _ctx.$t('common.options')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Settings, {
            onDoneEdit: _ctx.handleReload,
            resource: _ctx.resource,
            workspace: _ctx.workspace
          }, null, 8, ["onDoneEdit", "resource", "workspace"])
        ]),
        _: 1
      }, 8, ["message"])
    ]),
    fixed: _withCtx(() => [
      (
          (_ctx.resource.hasEventContext || _ctx.resource.hasPlannedContext) &&
          !_ctx.showSearch
        )
        ? (_openBlock(), _createBlock(_component_LiveAgenda, {
            key: 0,
            resource: _ctx.resource,
            workspace: _ctx.workspace
          }, null, 8, ["resource", "workspace"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        style: _normalizeStyle({ display: _ctx.showSearch ? 'block' : 'none' })
      }, [
        _createVNode(_component_va_input, {
          ref: "input",
          modelValue: _ctx.search,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          placeholder: _ctx.$t('news.search-in-news-feed'),
          onKeyup: _ctx.handleKey
        }, {
          appendInner: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (!_ctx.searchLoading)
                ? (_openBlock(), _createBlock(_component_anp_popover, {
                    key: 0,
                    message: _ctx.$t('common.search')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_va_button, {
                        icon: "arrow_forward",
                        color: "dark",
                        flat: "",
                        onClick: _ctx.handleSearch
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }, 8, ["message"]))
                : (_openBlock(), _createBlock(_component_va_progress_circle, {
                    key: 1,
                    size: "small",
                    thickness: 0.2,
                    indeterminate: ""
                  }, null, 8, ["thickness"])),
              _createVNode(_component_anp_popover, {
                message: _ctx.$t('actions.close')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_button, {
                    icon: "close",
                    color: "dark",
                    flat: "",
                    onClick: _ctx.toggleSearch
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["message"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "placeholder", "onKeyup"])
      ], 4)
    ]),
    default: _withCtx(() => [
      (_ctx.renderItems.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.renderItems, (renderItem) => {
            return (_openBlock(), _createBlock(_component_anp_column_item, {
              key: renderItem.id,
              item: renderItem,
              resourceId: _ctx.resource.resourceId,
              articleRouteName: _ctx.NEWS_ARTICLE,
              articleIds: _ctx.renderItemIds,
              showDescription: 
          _ctx.resource.settings?.showSnippet || _ctx.resource.settings?.showDetails
        ,
              showImage: _ctx.resource.settings?.showImages,
              showMeta: !_ctx.resource.settings?.showCompact
            }, null, 8, ["item", "resourceId", "articleRouteName", "articleIds", "showDescription", "showImage", "showMeta"]))
          }), 128))
        : (!_ctx.showSearch && !_ctx.renderItems.length)
          ? (_openBlock(), _createBlock(_component_anp_column_skeleton, {
              key: 1,
              text: "",
              meta: ""
            }))
          : (!_ctx.showSearch && _ctx.isEmpty)
            ? (_openBlock(), _createBlock(_component_anp_column_empty, {
                key: 2,
                type: "news"
              }))
            : _createCommentVNode("", true),
      (!_ctx.showSearch && _ctx.allLoaded)
        ? (_openBlock(), _createBlock(_component_anp_column_all_loaded, { key: 3 }))
        : (!_ctx.showSearch && _ctx.loadingMore)
          ? (_openBlock(), _createBlock(_component_anp_column_loader, { key: 4 }))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "id", "title", "anchor"]))
}