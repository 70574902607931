import { WORKSPACE_KINDS } from '@/config/enums';
import { CALENDAR, NEWS } from '@/config/routes';
import store from '@/store';

export default (): string => {
  const { workspaces, auth } = store.state;
  const { features } = auth.user || {};

  const hasCalendars = workspaces.items?.some(
    (item) => item.wsKind === WORKSPACE_KINDS.COLUMN,
  );

  return hasCalendars && features?.startWithAgenda ? CALENDAR : NEWS;
};
