import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "display-6 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_message = _resolveComponent("anp-message")!
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_input = _resolveComponent("va-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('settings.my-details')), 1),
    _createVNode(_component_anp_message, { message: _ctx.message }, null, 8, ["message"]),
    _createVNode(_component_anp_label, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('user.first-name')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_va_input, {
      modelValue: _ctx.values.firstName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.firstName) = $event)),
      rules: [_ctx.required]
    }, null, 8, ["modelValue", "rules"]),
    _createVNode(_component_anp_label, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('user.last-name')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_va_input, {
      modelValue: _ctx.values.lastName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.lastName) = $event)),
      rules: [_ctx.required]
    }, null, 8, ["modelValue", "rules"]),
    _createVNode(_component_anp_label, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('user.email')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_va_input, {
      modelValue: _ctx.values.email,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.email) = $event)),
      disabled: true
    }, null, 8, ["modelValue"]),
    _createVNode(_component_anp_label, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('user.mobile-phone')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_va_input, {
      modelValue: _ctx.values.mobilePhone,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.mobilePhone) = $event)),
      rules: [_ctx.required, _ctx.validPhone]
    }, null, 8, ["modelValue", "rules"])
  ], 64))
}