import { CONTEXT_TYPES } from '@/config/enums';
import mapEvents from '@/services/mapping/mapEvents';

import http from '../http';
import listOptions, { ListOptions } from '../listOptions';
import {
  Article,
  ArticleList,
  ArticleMail,
  ArticleMedia,
  ArticleRelated,
} from './article.types';
import { SearchQuery } from './search';

export interface Source {
  id: string;
  title: string;
  bundleTitle: string;
}

const index = (): Promise<Source[]> => {
  return http.get('/sources');
};

const items = async (
  id: string,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);
  const data = await http.get(`/sources/${id}/items${params}`);

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const article = (
  resourceId: string,
  id: string,
  options?: ListOptions,
): Promise<Article> => {
  const params = listOptions(options);
  return http.get(`/sources/${resourceId}/items/${id}${params}`);
};

const articleMedia = (
  resourceId: string,
  id: string,
): Promise<ArticleMedia[]> => {
  return http.get(`/sources/${resourceId}/items/${id}/media`);
};

const articleRelated = (
  resourceId: string,
  id: string,
  options: ListOptions,
): Promise<ArticleRelated> => {
  const params = listOptions(options);
  return http.get(`/sources/${resourceId}/items/${id}/related${params}`);
};

const articleMail = (item: ArticleMail): Promise<undefined> => {
  return http.post(
    `/sources/${item.resource.resourceId}/items/${item.id}/mail`,
    {
      username: item.email,
      subject: item.subject,
      comment: item.message,
    },
  );
};

const context = async (
  id: string,
  type: CONTEXT_TYPES,
): Promise<ArticleList> => {
  const params = listOptions();
  const data = await http.get(`/sources/${id}/context/${type}${params}`);

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const search = async (
  item: SearchQuery,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);
  const data = await http.post(
    `/sources/${item.id}/items/searchresult${params}`,
    item,
  );

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

export default {
  index,
  items,
  article,
  articleMedia,
  articleRelated,
  articleMail,
  context,
  search,
};
