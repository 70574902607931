
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import useValidation from '@/hooks/useValidation';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const modal = ref();

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const handleDelete = () => {
      modal.value?.hide();

      store.dispatch('workspaces/deleteResourceItem', {
        resourceId: props.id,
      });
    };

    return {
      modal,
      hide,
      show,
      handleDelete,
      ...useValidation(),
    };
  },
});
