import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_list_item_label = _resolveComponent("va-list-item-label")!
  const _component_va_list_item_section = _resolveComponent("va-list-item-section")!
  const _component_anp_clickable = _resolveComponent("anp-clickable")!
  const _component_va_list_item = _resolveComponent("va-list-item")!
  const _component_va_list = _resolveComponent("va-list")!
  const _component_va_button_dropdown = _resolveComponent("va-button-dropdown")!
  const _component_EditQuery = _resolveComponent("EditQuery")!
  const _component_ColumnMove = _resolveComponent("ColumnMove")!
  const _component_ColumnDelete = _resolveComponent("ColumnDelete")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_va_button_dropdown, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.opened = true)),
      icon: "more_vert",
      color: "primary",
      position: "bottom-end",
      flat: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_va_list, { class: "px-0 py-1 -m-2" }, {
          default: _withCtx(() => [
            (
          ((_ctx.features?.managePrivateWorkspaces &&
            _ctx.features?.managePrivateQueries) ||
            (_ctx.features?.manageSharedWorkspaces &&
              _ctx.features?.manageSharedQueries)) &&
          _ctx.isEditable
        )
              ? (_openBlock(), _createBlock(_component_va_list_item, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_anp_clickable, { onClick: _ctx.showEditQuery }, {
                      default: _withCtx(() => [
                        _createVNode(_component_va_list_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_va_list_item_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('search.edit-search-query')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (
          ((_ctx.workspace.wsType === _ctx.WORKSPACE_TYPES.PRIVATE &&
            _ctx.features?.managePrivateWorkspaces &&
            _ctx.features?.managePrivateQueries) ||
            (_ctx.workspace.wsType === _ctx.WORKSPACE_TYPES.SHARED &&
              _ctx.features?.manageSharedWorkspaces &&
              _ctx.features?.manageSharedQueries)) &&
          _ctx.isQuery
        )
              ? (_openBlock(), _createBlock(_component_va_list_item, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_anp_clickable, { onClick: _ctx.showMove }, {
                      default: _withCtx(() => [
                        _createVNode(_component_va_list_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_va_list_item_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('actions.move')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (
          ((_ctx.features?.managePrivateWorkspaces &&
            _ctx.features?.managePrivateQueries) ||
            (!_ctx.features?.manageSharedWorkspaces &&
              !_ctx.features?.manageSharedQueries)) &&
          _ctx.isEditable
        )
              ? (_openBlock(), _createBlock(_component_va_list_item, { key: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_anp_clickable, { onClick: _ctx.showDelete }, {
                      default: _withCtx(() => [
                        _createVNode(_component_va_list_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_va_list_item_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('actions.delete')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.opened)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_EditQuery, {
            ref: "editQueryModal",
            onDone: _ctx.handleDone,
            resource: _ctx.resource
          }, null, 8, ["onDone", "resource"]),
          _createVNode(_component_ColumnMove, {
            ref: "moveModal",
            resource: _ctx.resource,
            workspaceId: _ctx.workspace.id
          }, null, 8, ["resource", "workspaceId"]),
          _createVNode(_component_ColumnDelete, {
            ref: "deleteModal",
            id: _ctx.resource.resourceId
          }, null, 8, ["id"])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}