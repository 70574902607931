import { computed, ComputedRef, onMounted, onUnmounted, ref } from 'vue';
import { LocationQueryValue, useRoute, useRouter } from 'vue-router';

interface UseNavigate {
  hasPrevious: ComputedRef<boolean>;
  hasNext: ComputedRef<boolean>;
  showNavigation: ComputedRef<boolean>;
  previousArticle(): void;
  nextArticle(): void;
}

export default (): UseNavigate => {
  const router = useRouter();
  const route = useRoute();

  const routeName = computed(() => route.name);
  const workspaceId = computed(() => route.params.id);
  const resourceId = computed(() => route.params.resourceId);
  const articleId = computed(() => route.params.articleId);
  const articleIds = ref<string[]>(history.state.articleIds);

  const currentIndex = computed(() => {
    const index = articleIds.value.findIndex((id) => id === articleId.value);

    if (!index || index === -1) return 0;
    return index;
  });

  const previousId = computed(() => articleIds.value?.[currentIndex.value - 1]);
  const nextId = computed(() => articleIds.value?.[currentIndex.value + 1]);

  const hasPrevious = computed(() => Boolean(previousId.value));
  const hasNext = computed(() => Boolean(nextId.value));

  const showNavigation = computed(() =>
    articleIds.value?.includes(articleId.value as string),
  );

  const navigateTo = async (articleId: ComputedRef<LocationQueryValue>) => {
    const articleIds = history.state.articleIds;
    const articles = history.state.articles;

    await router.push({
      name: routeName.value as string,
      params: {
        id: workspaceId.value,
        resourceId: resourceId.value,
        articleId: articleId.value,
      },
    });

    history.replaceState(
      {
        ...history.state,
        articleIds,
        articles,
      },
      '',
    );
  };

  const previousArticle = () => {
    navigateTo(previousId);
  };

  const nextArticle = () => {
    navigateTo(nextId);
  };

  const handleKey = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault();
        return !articleIds.value && router.go(-1);
      case 'ArrowUp':
        event.preventDefault();
        return hasPrevious.value && previousArticle();
      case 'ArrowDown':
        event.preventDefault();
        return hasNext.value && nextArticle();
      default:
        return;
    }
  };

  const handleState = (event: PopStateEventInit) => {
    articleIds.value = event.state.articleIds;
  };

  onMounted(() => {
    document.addEventListener('keydown', handleKey);
    window.addEventListener('popstate', handleState);

    setTimeout(() => {
      articleIds.value = history.state.articleIds;
    });
  });

  onUnmounted(() => {
    document.removeEventListener('keydown', handleKey);
    window.removeEventListener('popstate', handleState);
  });

  return {
    hasPrevious,
    hasNext,
    showNavigation,
    previousArticle,
    nextArticle,
  };
};
