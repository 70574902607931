
import { computed, defineComponent } from 'vue';

import { Article } from '@/services/api/modules/article.types';

export default defineComponent({
  props: {
    article: {
      type: Object as () => Article,
      required: true,
    },
  },

  setup(props) {
    const links = computed(() =>
      props.article.links.filter(
        (link) =>
          link.kind === 'origin' ||
          link.kind === 'related' ||
          link.kind === 'terms',
      ),
    );

    const anpLinks = computed(() =>
      props.article.links.filter((link) => link.kind === 'anp'),
    );

    return {
      links,
      anpLinks,
    };
  },
});
