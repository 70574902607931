
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  props: {
    anchor: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    square: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  setup(props) {
    const route = useRoute();
    const flicker = computed(() => route.hash === `#${props.anchor}`);

    return { flicker };
  },
});
