import {
  createRouter,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';

import { WORKSPACE_KINDS } from '@/config/enums';
import {
  AUTH_LOGIN,
  CALENDAR,
  CLIPPINGS,
  NEWS,
  PRIVACY_POLICY,
  SEARCH,
} from '@/config/routes';
import modules from '@/modules';
import { Workspace } from '@/services/api/modules/workspaces';
import defaultRoute from '@/services/router/defaultRoute';
import store from '@/store';

import history from './history';

const routes: Array<RouteRecordRaw> = modules.routes;

const scrollBehavior = (to: RouteLocationNormalized) => {
  if (to.hash && to.hash.length > 1) {
    const main = document.querySelector<HTMLElement>('.news .view');
    const column = main?.querySelector<HTMLElement>(to.hash);

    if (main && column) {
      const mainLeft = main.getBoundingClientRect().left;
      const columnLeft = column.getBoundingClientRect().left;
      const columnStyle = window.getComputedStyle(column);
      const columnPadding = Number(columnStyle.paddingLeft.replace('px', ''));

      const distance = columnLeft - columnPadding - mainLeft;

      main.scrollBy(distance, 0);
    }
  }
};

const router = createRouter({
  history,
  routes,
  scrollBehavior,
});

const hasWorkspaceOfType = (kinds: WORKSPACE_KINDS[]) => {
  return store.state.workspaces.items.some((item) =>
    kinds.includes(item.wsKind),
  );
};

const getDefaultNewsDirectory = () => {
  const activeNewsDirectory = store.getters['objects/activeNewsDirectory'];

  const items = store.getters['workspaces/getItems'] as Workspace[];
  const newsDirectories = items?.filter(
    (item) => item.wsKind === WORKSPACE_KINDS.COLUMN,
  );
  const [firstNewsDirectory] = newsDirectories ?? [];

  return activeNewsDirectory ?? firstNewsDirectory.id;
};

const getDefaultCalendar = () => {
  const items = store.getters['workspaces/getItems'] as Workspace[];
  const calenders = items?.filter(
    (item) =>
      item.wsKind === WORKSPACE_KINDS.CALENDARS ||
      item.wsKind === WORKSPACE_KINDS.CALENDAR,
  );

  const [firstWorkspace] = calenders ?? [];
  const [firstCalendar] = firstWorkspace?.items ?? [];
  const defaultCalendar = firstCalendar?.resourceId;

  return defaultCalendar;
};

export const checkIsPublic = (route: RouteLocationNormalized): boolean => {
  return route.path.startsWith('/auth') || route.name === PRIVACY_POLICY;
};

router.beforeEach((to, from, next) => {
  const { authenticated, user } = store.state.auth;

  if (!checkIsPublic(to) && !authenticated) {
    return next({ name: AUTH_LOGIN });
  }

  // Protected routes
  if (
    (to.name === AUTH_LOGIN && authenticated) ||
    (to.name === CLIPPINGS && !user?.features.useClippings) ||
    (to.name === SEARCH && !user?.features.useSourceSearch) ||
    (to.name === CALENDAR &&
      !hasWorkspaceOfType([
        WORKSPACE_KINDS.CALENDARS,
        WORKSPACE_KINDS.CALENDAR,
      ])) ||
    (to.name === NEWS && !hasWorkspaceOfType([WORKSPACE_KINDS.COLUMN]))
  ) {
    return next({ name: defaultRoute() });
  }

  // Send to default calendar when no id param given
  if (to.name === CALENDAR && !to.params.resourceId) {
    return next({
      name: CALENDAR,
      params: { resourceId: getDefaultCalendar() },
    });
  }

  // Send to default news when no id param given
  if (to.name === NEWS && !to.params.id) {
    return next({ name: NEWS, params: { id: getDefaultNewsDirectory() } });
  } else if (to.name === NEWS && to.params.id) {
    store.dispatch('objects/saveActiveNewsDirectory', to.params.id);
  }

  next();
});

export default router;
