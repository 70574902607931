import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 2 }
const _hoisted_2 = { class: "row justify--end mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button_toggle = _resolveComponent("va-button-toggle")!
  const _component_Item = _resolveComponent("Item")!
  const _component_Draggable = _resolveComponent("Draggable")!
  const _component_va_card_title = _resolveComponent("va-card-title")!
  const _component_va_card_content = _resolveComponent("va-card-content")!
  const _component_va_card = _resolveComponent("va-card")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_ctx.workspaces)
    ? (_openBlock(), _createBlock(_component_va_modal, {
        key: 0,
        ref: "modal",
        stateful: true,
        title: 
      _ctx.type === _ctx.SECTION_TYPES.CALENDAR
        ? _ctx.$t('calendar.manage-directories')
        : _ctx.$t('news.manage-directories')
    ,
        "hide-default-actions": true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_button_toggle, {
            color: "primary",
            modelValue: _ctx.displayType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayType) = $event)),
            options: _ctx.displayTypes,
            class: "mb-4",
            outline: ""
          }, null, 8, ["modelValue", "options"]),
          (_ctx.displayType === 'workspaces')
            ? (_openBlock(), _createBlock(_component_Draggable, {
                key: 0,
                modelValue: _ctx.workspaces,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.workspaces) = $event)),
                group: "workspaces",
                onChange: _ctx.handleChangeWorkspace,
                handle: ".handle",
                "item-key": "resourceId"
              }, {
                item: _withCtx(({ element: workspace }) => [
                  _createVNode(_component_Item, {
                    key: workspace.resourceId,
                    item: workspace,
                    type: _ctx.type,
                    draggable: 
            workspace.arrangeable && workspace.wsType !== _ctx.WORKSPACE_TYPES.SYSTEM
          ,
                    empty: workspace.items.length === 0,
                    editable: workspace.editable
                  }, null, 8, ["item", "type", "draggable", "empty", "editable"])
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"]))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.workspaces, (workspace) => {
                return (_openBlock(), _createBlock(_component_va_card, {
                  key: workspace.id,
                  class: "mb-4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_va_card_title, null, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(workspace.title), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_va_card_content, null, {
                      default: _withCtx(() => [
                        (workspace.arrangeable)
                          ? (_openBlock(), _createBlock(_component_Draggable, {
                              key: 0,
                              modelValue: workspace.resources,
                              "onUpdate:modelValue": ($event: any) => ((workspace.resources) = $event),
                              group: 
            workspace.wsType === _ctx.WORKSPACE_TYPES.SYSTEM ? 'system' : 'resource'
          ,
                              onChange: (event) => _ctx.handleChangeResource(event, workspace),
                              handle: ".handle",
                              "item-key": "resourceId"
                            }, {
                              item: _withCtx(({ element: resource }) => [
                                _createVNode(_component_Item, {
                                  key: resource.resourceId,
                                  item: resource,
                                  type: _ctx.type,
                                  draggable: workspace.arrangeable,
                                  editable: workspace.editable,
                                  toggleable: ""
                                }, null, 8, ["item", "type", "draggable", "editable"])
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue", "group", "onChange"]))
                          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(workspace.resources, (resource) => {
                              return (_openBlock(), _createBlock(_component_Item, {
                                key: resource.resourceId,
                                item: resource,
                                type: _ctx.type,
                                editable: workspace.editable,
                                toggleable: ""
                              }, null, 8, ["item", "type", "editable"]))
                            }), 128)),
                        (!workspace.resources.length)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.type === _ctx.SECTION_TYPES.CALENDAR
              ? _ctx.$t('calendar.empty-directory')
              : _ctx.$t('news.empty-directory')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_va_button, {
              color: "primary",
              onClick: _ctx.hide
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.close')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}