export enum ENVIRONMENT_PATHS {
  APP = 'app',
  TEST = 'test',
}

export enum DISPLAY_TYPES {
  DEFAULT = 'default',
  COMPACT = 'compact',
  SNIPPET = 'snippet',
  EXTENDED = 'extended',
}

export enum SORT_TYPES {
  RELEVANCE = 'relevance',
  DATE = 'date',
}

export enum SECTION_TYPES {
  NEWS = 'news',
  CALENDAR = 'calendar',
}

export enum MESSAGE_TYPES {
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum COLLECTION_TYPES {
  WORKSPACE = 'workspace',
  RESOURCE = 'resource',
}

export enum RESOURCE_KINDS {
  QUERY = 'QUERY',
  SOURCE = 'SOURCE',
  CALENDARS = 'CALENDARS',
  CALENDAR = 'CALENDAR',
}

export enum WORKSPACE_KINDS {
  COLUMN = 'COLUMN',
  CALENDARS = 'CALENDARS',
  CALENDAR = 'CALENDAR',
}

export enum WORKSPACE_TYPES {
  PRIVATE = 'PRIVATE',
  SHARED = 'SHARED',
  SYSTEM = 'SYSTEM',
}

export enum CONTEXT_TYPES {
  EVENTS = 'events',
  PLANNED = 'planned',
}

export enum ARTICLE_TYPES {
  TEXTARTICLE = 'TEXTARTICLE',
  EVENT = 'EVENT',
}

export enum ARTICLE_RELATIONSHIP {
  ALIKE = 'alike',
  TOPIC = 'topic',
}

export enum COLLECTION_KINDS {
  WORKFOLDER = 'WORKFOLDER',
  SHAREDFOLDER = 'SHAREDFOLDER',
}

export enum COLLECTION_ACTIONS {
  ADD = 'add',
  REMOVE = 'remove',
}

export enum RELATED_KINDS {
  PERSON = 'PERSON',
  WEBARTICLE = 'WEBARTICLE',
}

export enum OBJECT_KINDS {
  PREFERENCES = 'preferences',
}

export enum OBJECT_SCOPES {
  PRIVATE = 'private',
}

export enum NEWSLETTER_FORMATS {
  PDF = 'pdf',
  HTML = 'html',
}

export enum ARTICLE_MEDIA_TYPES {
  IMAGE_MEDIUM = 'imgMid',
  IMAGE_SMALL = 'imgLow',
  AUDIO = 'audio',
  VIDEO = 'video',
}
