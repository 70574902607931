import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4d13708"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "article__title mb-4" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 2,
  class: "article__meta mb-6"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { key: 4 }
const _hoisted_11 = { key: 5 }
const _hoisted_12 = { key: 6 }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "article__meta" }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Media = _resolveComponent("Media")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", {
        innerHTML: _ctx.article.title
      }, null, 8, _hoisted_2),
      _createElementVNode("img", {
        class: "article__title-image",
        src: `${_ctx.baseUri}sources/${_ctx.resourceId}/logo`,
        alt: _ctx.$t('common.origin'),
        loading: "lazy"
      }, null, 8, _hoisted_3)
    ]),
    (_ctx.article.editorialNote)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: _ctx.article.editorialNote,
          class: "article__editor-note my-4"
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.article.kind === _ctx.ARTICLE_TYPES.EVENT)
      ? (_openBlock(), _createBlock(_component_Calendar, {
          key: 1,
          article: _ctx.article,
          docked: _ctx.docked
        }, null, 8, ["article", "docked"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.formattedDate)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.formattedDate), 1))
            : _createCommentVNode("", true),
          (_ctx.formattedTime)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.formattedTime), 1))
            : _createCommentVNode("", true),
          (_ctx.article.sourceTitle)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.article.sourceTitle), 1))
            : _createCommentVNode("", true),
          (_ctx.article.urgency)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('common.priority')) + ": " + _toDisplayString(_ctx.article.urgency), 1))
            : _createCommentVNode("", true),
          (_ctx.article.version)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('common.version')) + ": " + _toDisplayString(_ctx.article.version), 1))
            : _createCommentVNode("", true),
          (_ctx.article.wordCount)
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('common.words')) + ": " + _toDisplayString(_ctx.article.wordCount), 1))
            : _createCommentVNode("", true),
          (_ctx.article.readCount)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('common.readCount')) + ": " + _toDisplayString(_ctx.article.readCount), 1))
            : _createCommentVNode("", true)
        ])),
    (_ctx.article.hasMedia)
      ? (_openBlock(), _createBlock(_component_Media, {
          key: _ctx.id,
          resource: _ctx.resource,
          articleId: _ctx.id,
          title: _ctx.article.title
        }, null, 8, ["resource", "articleId", "title"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "article__body mb-12",
      innerHTML: _ctx.article.bodyText
    }, null, 8, _hoisted_13),
    _createElementVNode("div", _hoisted_14, [
      (_ctx.article.authors)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.article.authors, (author, index) => {
            return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(author), 1))
          }), 128))
        : _createCommentVNode("", true),
      (_ctx.article.itemId)
        ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.article.itemId), 1))
        : _createCommentVNode("", true),
      (_ctx.article.copyrightNotice)
        ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.article.copyrightNotice) + " " + _toDisplayString(_ctx.$t('common.copyrights')), 1))
        : _createCommentVNode("", true)
    ])
  ], 64))
}