
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import useValidation from '@/hooks/useValidation';
import { Article } from '@/services/api/modules/article.types';
import { Resource } from '@/services/api/modules/workspaces';

export default defineComponent({
  props: {
    article: {
      type: Object as () => Article,
      required: true,
    },
    resource: {
      type: Object as () => Resource,
      required: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const modal = ref();
    const form = ref();
    const loading = ref(false);
    const email = ref('');
    const strippedTitle = computed(() =>
      props.article.title.replace(/(<([^>]+)>)/gi, ''),
    );
    const subject = ref(`${t('common.anp-app')}: ${strippedTitle.value}`);
    const message = ref(t('news.mail-article-message'));

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const handleSubmit = () => {
      loading.value = true;

      store
        .dispatch('articles/sendMail', {
          resource: props.resource,
          id: props.article.id,
          email: email.value,
          subject: subject.value,
          message: message.value,
        })
        .then(() => {
          email.value = '';
          modal.value?.hide();
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      modal,
      form,
      loading,
      email,
      subject,
      message,
      hide,
      show,
      handleSubmit,
      ...useValidation(),
    };
  },
});
