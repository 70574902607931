import { ActionContext } from 'vuex';

import api from '@/services/api';
import { Source } from '@/services/api/modules/sources';
import { State as R } from '@/store/index';

export interface State {
  items: Source[];
}

export default {
  namespaced: true,

  state: {
    items: [],
  },

  mutations: {
    setItems(state: State, items: Source[]): void {
      state.items = items;
    },
  },

  actions: {
    async fetchItems({ commit }: ActionContext<State, R>): Promise<void> {
      const items = await api.sources.index();
      commit('setItems', items);
    },
  },

  getters: {},
};
