import http from '../http';

export interface Notification {
  id: string;
  notifyFrequency: number;
  notificationKind: string[];
}

export interface StoreNotification {
  resourceId: string;
  notifyFrequency: number;
}

const notifications = (): Promise<Notification[]> => {
  return http.get('/settings/alerts/notification/resources');
};

const storeNotification = (
  item: StoreNotification,
): Promise<Notification[]> => {
  return http.post(`/settings/alerts/notification/resources`, {
    resourceKind: 'QUERY',
    notificationKind: 'EMAIL',
    notifyTime: '00:00',
    ...item,
  });
};

const updateNotification = (
  item: StoreNotification,
): Promise<Notification[]> => {
  return http.put(
    `/settings/alerts/notification/resources/${item.resourceId}`,
    {
      notifyFrequency: item.notifyFrequency,
      notifyTime: '00:00',
    },
  );
};

const removeNotification = (id: string): Promise<Notification[]> => {
  return http.del(`/settings/alerts/notification/resources/${id}`);
};

export default {
  notifications,
  storeNotification,
  updateNotification,
  removeNotification,
};
