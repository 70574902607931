
import { computed, defineComponent } from 'vue';

import { DISPLAY_TYPES } from '@/config/enums';
import { SEARCH_ARTICLE } from '@/config/routes';
import { ArticleListItem } from '@/services/api/modules/article.types';

export default defineComponent({
  props: {
    items: {
      type: Array as () => ArticleListItem[],
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    square: {
      type: Boolean,
      required: true,
    },
    showDescription: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const itemsIds = computed(() => props.items.map((item) => item.id));

    return {
      itemsIds,
      SEARCH_ARTICLE,
      DISPLAY_TYPES,
    };
  },
});
