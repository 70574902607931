import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-564e8c6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "article" }
const _hoisted_2 = { class: "article__main" }
const _hoisted_3 = { class: "article__sidebar" }
const _hoisted_4 = {
  key: 1,
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Content = _resolveComponent("Content")!
  const _component_Actions = _resolveComponent("Actions")!
  const _component_Versions = _resolveComponent("Versions")!
  const _component_Links = _resolveComponent("Links")!
  const _component_Entities = _resolveComponent("Entities")!
  const _component_Related = _resolveComponent("Related")!
  const _component_va_progress_circle = _resolveComponent("va-progress-circle")!
  const _component_va_card = _resolveComponent("va-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", { docked: _ctx.docked }]),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
  }, [
    _createVNode(_component_va_card, {
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.data)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_Column, {
                  resource: _ctx.resource,
                  hidden: _ctx.docked,
                  articleId: _ctx.articleId
                }, null, 8, ["resource", "hidden", "articleId"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_Content, {
                    article: _ctx.data,
                    id: _ctx.articleId,
                    resource: _ctx.resource,
                    docked: _ctx.docked
                  }, null, 8, ["article", "id", "resource", "docked"])
                ]),
                _createElementVNode("aside", _hoisted_3, [
                  _createVNode(_component_Actions, {
                    article: _ctx.data,
                    resource: _ctx.resource,
                    docked: _ctx.docked,
                    onDock: _ctx.toggleDocked,
                    onClose: _ctx.handleClose
                  }, null, 8, ["article", "resource", "docked", "onDock", "onClose"]),
                  (_ctx.data.otherVersions)
                    ? (_openBlock(), _createBlock(_component_Versions, {
                        key: 0,
                        article: _ctx.data
                      }, null, 8, ["article"]))
                    : _createCommentVNode("", true),
                  (_ctx.data.links?.length)
                    ? (_openBlock(), _createBlock(_component_Links, {
                        key: 1,
                        article: _ctx.data
                      }, null, 8, ["article"]))
                    : _createCommentVNode("", true),
                  (_ctx.data.entities?.length)
                    ? (_openBlock(), _createBlock(_component_Entities, {
                        key: 2,
                        article: _ctx.data
                      }, null, 8, ["article"]))
                    : _createCommentVNode("", true),
                  (_ctx.data.suggestedSources?.length)
                    ? (_openBlock(), _createBlock(_component_Related, {
                        key: 3,
                        title: _ctx.$t('common.suggested-sources'),
                        items: _ctx.data.suggestedSources
                      }, null, 8, ["title", "items"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_Related, {
                    title: _ctx.$t('common.related'),
                    type: _ctx.ARTICLE_RELATIONSHIP.ALIKE,
                    articleId: _ctx.articleId,
                    resource: _ctx.resource
                  }, null, 8, ["title", "type", "articleId", "resource"]),
                  (_ctx.data.relationships.includes(_ctx.ARTICLE_RELATIONSHIP.TOPIC))
                    ? (_openBlock(), _createBlock(_component_Related, {
                        key: 4,
                        title: _ctx.$t('common.topic'),
                        type: _ctx.ARTICLE_RELATIONSHIP.TOPIC,
                        articleId: _ctx.articleId,
                        resource: _ctx.resource
                      }, null, 8, ["title", "type", "articleId", "resource"]))
                    : _createCommentVNode("", true)
                ])
              ], 64))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_va_progress_circle, {
                  size: "large",
                  thickness: 0.2,
                  indeterminate: ""
                }, null, 8, ["thickness"])
              ]))
        ])
      ]),
      _: 1
    })
  ], 2))
}