
import { computed, defineComponent, provide, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import Main from '@/components/navigation/Main.vue';
import Sub from '@/components/navigation/Sub.vue';
import Top from '@/components/navigation/Top.vue';
import mainNavigation from '@/config/navigation/main';
import { State } from '@/store';

export type ArticleDockedType = Ref<string>;
export const ArticleDocked = Symbol('ArticleDocked');
export type ArticleSetDockedType = (value: boolean) => void;
export const ArticleSetDocked = Symbol('ArticleSetDocked');

export default defineComponent({
  components: {
    Main,
    Sub,
    Top,
  },

  setup() {
    const open = ref(true);
    const docked = ref(false);
    const route = useRoute();
    const store = useStore<State>();

    const mainItems = computed(() => mainNavigation(store));

    const current = computed(() =>
      mainItems.value.find((item) =>
        String(route.name).startsWith(String(item.to.name)),
      ),
    );

    const hasSub = computed(() => Boolean(current.value?.children));

    const toggleOpen = () => {
      open.value = !open.value;
    };

    const setDocked = (value: boolean) => {
      docked.value = value;
    };

    provide(ArticleDocked, docked);
    provide(ArticleSetDocked, setDocked);

    return {
      open,
      docked,
      toggleOpen,
      mainItems,
      hasSub,
      current,
    };
  },
});
