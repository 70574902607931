
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { COLLECTION_TYPES, SECTION_TYPES } from '@/config/enums';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    idType: {
      type: String as () => COLLECTION_TYPES,
    },
    type: {
      type: String as () => SECTION_TYPES,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const modal = ref();
    const form = ref();
    const loading = ref(false);

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const title = computed(() => {
      if (props.idType === COLLECTION_TYPES.RESOURCE) {
        return props.type === SECTION_TYPES.CALENDAR
          ? t('calendar.delete-calendar')
          : t('news.delete-news-feed');
      } else {
        return props.type === SECTION_TYPES.CALENDAR
          ? t('calendar.delete-directory')
          : t('news.delete-directory');
      }
    });

    const confirm = computed(() => {
      if (props.idType === COLLECTION_TYPES.RESOURCE) {
        return props.type === SECTION_TYPES.CALENDAR
          ? t('calendar.delete-calendar-confirm')
          : t('news.delete-news-feed-confirm');
      } else {
        return props.type === SECTION_TYPES.CALENDAR
          ? t('calendar.delete-directory-confirm')
          : t('news.delete-directory-confirm');
      }
    });

    const handleDelete = () => {
      loading.value = true;

      if (props.idType === COLLECTION_TYPES.RESOURCE) {
        store
          .dispatch('workspaces/deleteResourceItem', {
            resourceId: props.id,
          })
          .then(() => {
            modal.value?.hide();
          })
          .finally(() => {
            loading.value = false;
          });
      } else {
        store
          .dispatch('workspaces/deleteItem', {
            id: props.id,
          })
          .then(() => {
            modal.value?.hide();
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    return {
      SECTION_TYPES,
      modal,
      form,
      loading,
      hide,
      show,
      title,
      confirm,
      handleDelete,
      ...useValidation(),
    };
  },
});
