
import { defineComponent } from 'vue';
import { LocationAsRelativeRaw, useRoute, useRouter } from 'vue-router';

import { MainItem } from '@/config/navigation/main';

export default defineComponent({
  props: {
    items: {
      type: Array as () => MainItem[],
      required: true,
    },
    wide: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const isActive = (to: LocationAsRelativeRaw) => {
      const toResolved = router.resolve(to);
      return route.path.startsWith(toResolved.path);
    };

    return {
      isActive,
    };
  },
});
