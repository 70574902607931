import { computed, Ref, ref } from 'vue';

import { ListOptions } from '@/services/api/listOptions';
import {
  ArticleList,
  ArticleListItem,
} from '@/services/api/modules/article.types';
import { SearchQuery } from '@/services/api/modules/search';

interface Module {
  search(item: SearchQuery, options: ListOptions): Promise<ArticleList>;
}

interface UseSearch {
  input: Ref<HTMLInputElement | undefined>;
  search: Ref<string>;
  showSearch: Ref<boolean>;
  searchLoading: Ref<boolean>;
  searchEmpty: Ref<boolean>;
  searchItems: Ref<ArticleListItem[]>;
  toggleSearch(): void;
  handleSearch(): void;
  handleKey(event: KeyboardEvent): void;
}

export default (id: string, module: Module): UseSearch => {
  const input = ref();
  const search = ref('');
  const showSearch = ref(false);
  const searchLoading = ref(false);
  const searchFetched = ref(false);
  const searchItems = ref<ArticleListItem[]>([]);

  const toggleSearch = () => {
    showSearch.value = !showSearch.value;

    if (!showSearch.value) {
      search.value = '';
      searchItems.value = [];
      searchLoading.value = false;
      searchFetched.value = false;
    } else {
      setTimeout(input.value?.focus);
    }
  };

  const handleSearch = async () => {
    if (search.value) {
      searchLoading.value = true;

      const data = await module.search(
        { id, queryText: search.value },
        { count: 30 },
      );

      searchItems.value = data.items;
      searchLoading.value = false;
      searchFetched.value = true;
    }
  };

  const handleKey = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const searchEmpty = computed(
    () =>
      searchFetched.value && !searchLoading.value && !searchItems.value.length,
  );

  return {
    input,
    search,
    showSearch,
    searchLoading,
    searchEmpty,
    searchItems,
    toggleSearch,
    handleSearch,
    handleKey,
  };
};
